import clone from "clone";
import actions from "./actions";

const initState = {
  loadingInitData: false,
  view: "gridView",
  viewTopbarCart: false,
  productQuantity: [],
  products: {},
  productFrom: "",
  mallType: "",
};
export default (state = initState, action) => {
  switch (action.type) {
    case actions.INIT_DATA:
      return {
        ...state,
        loadingInitData: true,
        productQuantity: action.payload.productQuantity,
        products: action.payload.products,
        mallType: action.payload.mallType,
        productFrom: action.payload.productFrom,
      };
    case actions.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case actions.VIEW_TOPBAR_CART:
      return {
        ...state,
        viewTopbarCart: action.viewTopbarCart,
      };
    case actions.UPDATE_DATA:
      return {
        ...state,
        products: clone(action.products),
        productQuantity: clone(action.productQuantity),
      };
    case actions.CLEAR_CART:
      return {
        ...state,
        products: [],
        productQuantity: [],
      };
    case actions.CHANGE_PRODUCT_FROM:
      return {
        ...state,
        productFrom: action.productFrom,
      };
    case actions.CHANGE_MALL_TYPE:
      return {
        ...state,
        mallType: action.mallType,
      };
    default:
      return state;
  }
};
